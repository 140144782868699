import CloseButton from '../common/button-x'
import Logo from '../common/header'

import LogoWhitefrom from '../resources/Logo-Blanco.png'

const PageThanks = () => {
    return (
        // h-screen grid-rows-3
        <div className='bg-[#EDE7E3] grid'>
            {/* Close Button  */}
            <div className='w-full pt-4 pb-8 grid grid-flow-col grid-cols-3'>
                <Logo />
                <div className='pr-8 flex justify-end col-start-3'>
                    {/* <a href={process.env.REACT_APP_PODEROSAS_URL}> */}
                    <a href="/">
                        <CloseButton
                        color='white'
                        width={1}
                        />
                    </a>
                </div>
            </div>

            {/* Donate Box */}
            {/* row-span-1 h-full  */}
            <div className='w-3/4 align-self-center justify-self-center text-center'>
                <p className='text-[#1E1E1E] py-8 px-8'>
                    Desde <span className='font-bold text-[#FF7C9B]'>Poderosas Colombia</span>, queremos desearte una feliz navidad y agradecerte porque se ha hecho una donación a tu nombre.
                    <br />
                    <br />
                    Poderosas Colombia es una ONG de Educación Integral para la Sexualidad (EIS) que fortalece el poder de decisión sobre su cuerpo, su sexualidad y su vida, de adolescentes y jóvenes en comunidades de mayor grado de vulnerabilidad en Colombia a través de Círculos de mujeres y Círculos de hombres.
                    <br />
                    <br />
                    Con tu aporte asumiste la asignatura pendiente por una educación integral en derechos sexuales y reproductivos, la única vía para cambiar la historia de pobreza, desigualdad y violencia por una de libertad, amor y placer. Esta contribución ha sido clave para que en el 2023 podamos seguir llegando a más lugares de Colombia. 
                    <br />
                    <br />
                    <span className='text-[#FF7C9B]'>¡Gracias por ser aliadx de este movimiento!</span>
                </p>
            </div>

            <div 
                className="bg-[#e34462] text-white text-center py-4"
            >   
                <img
                    className='mx-auto h-[180px]'
                    src={LogoWhitefrom}
                    alt='Poderosas Logo White'
                />
                <p>Todos los derechos reservados</p>
                <a href='#' alt='Policies'>Política de Privacidad</a>
                {process.env.REACT_APP_ENV && <p>Test</p>}
            </div>
        </div>
    );
}

export default PageThanks;