
const ButtonAmount = ({
    amount = 0,
    text = '',
    image = null,
    action = () => {}
}) => {
    // return <div
    //     onClick={action}
    //     className="bg-white cursor-pointer hover:bg-yellow-300"
    // >
    //     <p className="p-4 font-bold text-xl text-slate-600">${new Intl.NumberFormat('es-CO').format(amount)}</p>
    // </div>

    return <div
        onClick={action}
        className="
            flex-[0_0_50%] sm:flex-[0_0_30%] text-center
            text-[#1E1E1E] text-sm font-[400] 
            cursor-pointer grid content-between"
    >
        <img
            className="mx-auto h-[100px] w-auto"
            src={image}
            alt={amount} 
        />
        <p className="pt-2">{text}</p>
        <p className="pt-1 text-xl text-slate-600 hover:text-[#FF7C9B]">${new Intl.NumberFormat('es-CO').format(amount)}</p>
    </div>
}

export default ButtonAmount