import { useState } from 'react'

import ButtonAmount from '../../common/button-amount'
import ButtonGiveType from '../../common/button-give-type'
import TotalInput from '../../common/input-total'
import TypeSelector from '../../common/selector-type'

import EscribirImage from '../../resources/Escribir.png';
import FrutaImage from '../../resources/Fruta.png';
import LeamosImage from '../../resources/Leamos.png';
import MentoraImage from '../../resources/Mentora.png';
import NiñaImage from '../../resources/Niña.png';

const donationTypes = [
    {
        key: 'oneTime',
        text: 'Donación de una única vez',
        // options: ['10000', '50000', '250000', '500000'],
        options: [
            {
                text: 'Aseguras que unx Poderosx tenga su bitácora con todo el material pedagógico para ejercer sus derechos.',
                value: '45000',
                image: EscribirImage,
            },
            {
                text: 'Aseguras los refrigerios para un encuentro de 30 Poderosxs.',
                value: '140000',
                image: FrutaImage,
            },
            {
                text: 'Aseguras los materiales y papelería para lxs mentores.',
                value: '200000',
                image: LeamosImage,
            },
            {
                text: 'Aseguras la educación completa para unx Poderosx en derechos sexuales y reproductivos en su comunidad.',
                value: '450000',
                image: MentoraImage,
            },
            {
                text: 'Aseguras los honorarios de unx Poderosx para ser Mentorx de otras en sus comunidades.',
                value: '780000',
                image: NiñaImage,
            }
        ],
        paymentOptions: ['dona acá']
    },
    { 
        key: 'monthly',
        text: 'Donación mensual',
        // options: ['10000', '50000', '250000', '500000'],
        options: [
            {
                text: 'Aseguras que unx Poderosx tenga su bitácora con todo el material pedagógico para ejercer sus derechos.',
                value: '45000',
                image: EscribirImage,
            },
            {
                text: 'Aseguras los refrigerios para un encuentro de 30 Poderosxs.',
                value: '140000',
                image: FrutaImage,
            },
            {
                text: 'Aseguras los materiales y papelería para lxs mentores.',
                value: '200000',
                image: LeamosImage,
            },
            {
                text: 'Aseguras la educación completa para unx Poderosx en derechos sexuales y reproductivos en su comunidad.',
                value: '450000',
                image: MentoraImage,
            },
            {
                text: 'Aseguras los honorarios de unx Poderosx para ser Mentorx de otras en sus comunidades.',
                value: '780000',
                image: NiñaImage,
            }
        ],
        paymentOptions: ['dona acá']
    }
]

const DonateBox = ({
    handleDonation = () => {}
}) => {
    const [donationType, setDonationType] = useState(donationTypes[0])
    const [totalAMount, setTotalAmount] = useState(donationType.options[0].value)

    const handleAction = () => {
        handleDonation(true, donationType, totalAMount)
    }

    return <div className="w-full h-full text-center grid content-center">
        {/* Title    */}
        <h1 className="capitalize text-4xl font-bold text-[#FF7C9B] mb-4 ">
            Dona a<br /> Poderosas Colombia
        </h1>
        <p className='text-[#1E1E1E] pb-4'>
            ¡Tu aporte es muy poderoso!
            <br />
            <span className='font-light text-[#1E1E1E]'>{donationType.text}</span>
        </p>
        {/* <h2 className="uppercase text-2xl font-light text-[#1E1E1E] mb-4">
            
        </h2> */}

        {/* Donate Options */}
        {/* <div className="w-full mb-4 grid gap-4 grid-flow-col grid-cols-3 grid-rows-2"> */}
        <div className="mb-4 gap-4 flex flex-wrap justify-center">
            {
                donationType.options.map(data => 
                    <ButtonAmount
                        key={data.value}
                        amount={data.value}
                        image={data.image}
                        text={data.text}
                        action={() => setTotalAmount(data.value)} 
                    />
                )
            }
        </div>

        {/* Donate input */}
        {/* <div className='pt-4 justify-self-center w-2/4 grid grid-flow-col grid-cols-2 gap-4 mb-4'> */}
        <div className='pt-4 justify-self-center gap-4 mb-4 w-3/4 md:w-2/4 flex flex-wrap sm:flex-nowrap'>
            <TotalInput amount={totalAMount} handleChange={setTotalAmount}/>
            {
                donationType.paymentOptions.map(text => 
                    <ButtonGiveType
                        key={text}
                        text={text}
                        action={handleAction}
                    />
                )
            }
        </div>

        {/* Donation types selector */}
        <div className='w-full grid grid-cols-2 content-center'>
            {
                donationTypes.map(
                    types =>
                    <TypeSelector 
                        key={types.key}
                        text={types.text}
                        value={types.key}
                        checked={types.key === donationType.key}
                        types={types}
                        handleSelectionType={setDonationType}
                    /> 
                )
            }
        </div>
    </div>
}

export default DonateBox