import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";


import CloseButton from '../common/button-x'
import DonateBox from '../components/DonateBox'
import DonateModal from '../components/DonateModal'
// import CardModal from '../components/CardModal'
import Logo from '../common/header'
import Modal from '../common/modal'

import { useSaveDonationFunction } from '../hooks/useSaveDonationFunction'
import { useUpdateDonationWithTransaction } from '../hooks/useUpdateDonationWithTransaction'
import { useSavePaymentSource } from '../hooks/useSavePaymentSource'

const PageHome = () => {
    const navigate = useNavigate();

    const [modalInfo, setModalInfo] = useState(false)

    const [open, setOpen] = useState(false)
    // const [isModalCard, setIsModalCard] = useState()
    const [state, setState] = useState()
    const [message, setMessage] = useState()

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://checkout.wompi.co/widget.js";
        script.async = true;
        document.body.appendChild(script);
    return () => {
        document.body.removeChild(script);
        }
    }, []);

    const handleDonation = (state = false, type = {}, total = '0') => {
        setModalInfo({
        state,
        type,
        total
        })
    }

    const oneTime = async (res) => {
        // console.log('oneTime', res)
        const transactionCheckout = new window.WidgetCheckout({
            render: 'button',
            widgetOperation: 'purchase',
            publicKey: process.env.REACT_APP_WOMPI_PUBLIC_KEY,
            ...res
        })
        await transactionCheckout.open(async result => {
        setMessage('Procesando...')
        setState('pending')
        setOpen(true)
        // console.log('Result', result)
        var transaction = result.transaction
        // console.log('Transaction ID: ', transaction.id)
        // console.log('Transaction object: ', transaction)
        // eslint-disable-next-line react-hooks/rules-of-hooks  
        await useUpdateDonationWithTransaction(transaction)

        if (transaction.status === "APPROVED") {
            setState('ok')
            if (process.env.REACT_APP_ENV) {
            setMessage('TEST - Gracias, pronto te llegara un correo con mas informacion')
            } else {
            setMessage('Gracias, pronto te llegara un correo con mas informacion')
            }
            // setOpen(true)
            navigate("/thanks");
            return {
                status: true,
                message: 'Donación almacenada',
                transaction
            }
        } else {
            // console.log(transactionCheckout)
            setState('fail')
            setMessage(transaction.statusMessage)
            // setOpen(true)
            return {
                status: false,
                message: 'Donación fallida',
                transaction
            }
        }
        })
    }

    const monthly = async (res) => {
        const tokenizeCheckout = new window.WidgetCheckout({
            render: 'button',
            widgetOperation: 'tokenize',
            publicKey: process.env.REACT_APP_WOMPI_PUBLIC_KEY
        })
        await tokenizeCheckout.open(async result => {
        setMessage('Procesando...')
        setState('pending')
        setOpen(true)
        // console.log('Result', result)
        var paymentSource = result.payment_source
        // console.log('paymentSource', paymentSource)
        // eslint-disable-next-line react-hooks/rules-of-hooks  
        const resSource = await useSavePaymentSource(res, paymentSource)
        // console.log('resSource', resSource)

        if (resSource.status === 200) {
            setState('ok')
            if (process.env.REACT_APP_ENV) {
            setMessage('TEST - Tú información de pago se ha guardado, pronto te llegara un correo con más información')
            } else {
            setMessage('Tú información de pago se ha guardado, pronto te llegara un correo con más información')
            }
            // setOpen(true)
            navigate("/thanks");
            return {
                status: true,
                message: 'Donación almacenada',
                resSource
            }
        } else {
            setState('fail')
            setMessage(resSource.data.error.type || resSource.message)
            // setOpen(true)
            return {
                status: false,
                message: 'Donación fallida',
                resSource
            }
        }
        })
    }

    const handleExecDonation = async (paymentInfo) => {
        try {
        setMessage('Cargando...')
        setState('pending')
        setOpen(true)
        async function Pay() {
            return await useSaveDonationFunction({
            ...paymentInfo,
            ...modalInfo
            })
        }
        Pay().then(async res => {
            setMessage('Cargando...')
            setState('pending')
            setOpen(false)
            // console.log('res', res)

            // Monthly Flow
            if (res.donationType === 'monthly') {
            // setIsModalCard(res)
            await monthly(res)
            }

            if (res.donationType === 'oneTime') {
            // oneTime Flow
            delete res.donationType
            await oneTime(res)
            }

            
        }).catch(err => {
            console.log('Pay err', err)
            setState('fail')
            setMessage(err.message || err)
            setOpen(true)
        })

        } catch (error) {
        console.log('handleExecDonation err', error)
        setState('fail')
        setMessage(error.message)
        setOpen(true)
        }
    }

    // Not USED
    // const handleExecMonthlyDonation = (cardInfo) => {
    //   try {
    //     console.log('monthly', cardInfo)
    //     console.log(isModalCard.id)
    //     setState('ok')
    //     setMessage('Gracias, pronto te llegara un correo con mas informacion')
    //     setOpen(true)
    //   } catch (error) {
    //     console.log('error monthly', error)
    //     setOpen(true)
    //     setState('fail')
    //     setMessage(error.message)
    //   }
    // }

    
    return (
        // h-screen grid-rows-3
        <div className='bg-[#EDE7E3] grid'>
            {/* Close Button  */}
            <div className='w-full pt-4 grid grid-flow-col grid-cols-3'>
                <Logo />
                <div className='pr-8 flex justify-end col-start-3'>
                <a href={process.env.REACT_APP_PODEROSAS_URL}>
                    <CloseButton
                    color='white'
                    width={1}
                    />
                </a>
                </div>
            </div>

            {/* Donate Box */}
            {/* row-span-1 h-full  */}
            <div className='align-self-center justify-self-center'>
                <DonateBox handleDonation={handleDonation} />
            </div>

            <DonateModal modalInfo={modalInfo} handleExecDonation={handleExecDonation} />
            {/* <CardModal donationInfo={isModalCard} handleExecMonthlyDonation={handleExecMonthlyDonation} /> */}

            <Modal open={open} state={state} message={message} close={() => {setOpen(false);setState(null);}}/>
        </div>
    );
}

export default PageHome;