import { XMarkIcon } from '@heroicons/react/24/outline'

const ButtonX = ({
    action = () => {},
    color = 'black',
    width = .5
}) => {
    return <XMarkIcon 
        onClick={action}
        // className={`cursor-pointer h-full text-${color} stroke-[${width}px] hover:text-slate-400`}
        className={`cursor-pointer h-[60px] text-white stroke-[1px] hover:text-slate-400`}
    />
}

export default ButtonX