
const ButtonGiveType = ({ text = '', action = () => {} }) => {

    return <div
        onClick={action}
        // className='col-span-1 bg-[#9ed9d8] cursor-pointer hover:bg-yellow-300'
        className='
            w-full cursor-pointer
            bg-transparent rounded-md border-2 border-[#FF7C9B]
            hover:bg-[#FF7C9B]
        '
    >
        <p className="
            p-0 px-8 font-normal text-xl 
            text-[#FF7C9B] hover:text-white
            uppercase text-center align-middle leading-loose	
        ">
            {text}
        </p>
    </div>
}

export default ButtonGiveType