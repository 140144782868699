import './styles.css'

const TypeSelector = ({
    text = '',
    value = '',
    checked = false,
    types = [],
    handleSelectionType = () => {}
}) => {
    return <label
        className="text-slate-600 text-xl font-normal capitalize"
        onClick={() => handleSelectionType(types)}
    >
        <input type="checkbox" readOnly checked={checked}/>
        <span className="pl-2">{text}</span>
    </label>
}

export default TypeSelector