import axios from 'axios'

export const useSavePaymentSource = async (transaction, paymentSource) => {
    try {
        const res = await axios({
            method: 'POST',
            url: process.env.REACT_APP_FUNCTIONS_URL + '/savePaymentSource',
            // url: 'http://localhost:5001/poderosas-colombia/us-central1/savePaymentSource',
            headers: {
                authorization: `Bearer ${process.env.REACT_APP_FUNCTIONS_TOKEN}`
            },
            data: {
                transaction: transaction,
                paymentSource: paymentSource
            }
        })
        return res.data
    } catch (error) {
        console.log('err useSavePaymentSource:', error)
        throw new Error(error)
    }
}