import { 
  Routes,
  Route
} from "react-router-dom";

import PageHome from "./pages/home";
import PageThanks from "./pages/thanks";


function App() {
  return <Routes>
    <Route path="/thanks" element={<PageThanks />}/>
    <Route path="/" element={<PageHome />}/>
  </Routes>
}

export default App;
