import React from "react"
import { useForm } from "react-hook-form";

/*
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

const Form = ({
	setOpen = () => {},
	action = () => {},
	cancelButtonRef = null
}) => {
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues: {
			// email:'lola@gmail.com',
			// name: 'Lola Flores',
			// phone: '3040777777',
			// document: '123456789',
			// type_document: 'CC',
			// address: "Calle 123 # 4-5",
			// city: "Bogota",
			// municipality: "Cundinamarca",
			// country: "CO"
		}
    });

	const handleInputsForm = (data) => {
		action(data)
		setOpen(false)
	}
	
    const errorStyles = "block pt-2 text-[#e34462] text-sm"

    return (
      <>
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-2">
            <div className="border-t border-gray-200" />
          </div>
        </div>
  
        <div className="mt-10 sm:mt-0 overflow-scroll">
          <div className="md:grid">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form onSubmit={handleSubmit(handleInputsForm)}>
			  	<div className="grid grid-cols-6 gap-6">

					{/* name - Nombre y apellido */}
					<div className="col-span-6 sm:col-span-3">
						<label className="block text-sm font-medium text-gray-700">
							Nombre y apellido
							<input
								{...register(
									"name", { required: true, maxLength: 50 }
								)}
								type="text"
								placeholder="Escribe tu nombre aquí"
								className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF7C9B] focus:ring-[#FF7C9B] sm:text-sm"
							/>
						</label>
						{errors.name && 
                            <span className={errorStyles}>
                                Este campo es requerido para continuar
                            </span>
                        }
					</div>
					
					{/* email - Correo electrónico */}
					<div className="col-span-6 sm:col-span-3">
						<label className="block text-sm font-medium text-gray-700">
							Correo electrónico
							<input
								{...register(
									"email", { required: true, maxLength: 50 }
								)}
								type="email"
								placeholder="Escribe tu correo electrónico"
								className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF7C9B] focus:ring-[#FF7C9B] sm:text-sm"
							/>
						</label>
						{errors.email && 
                            <span className={errorStyles}>
                                Este campo es requerido para continuar
                            </span>
                        }
					</div>
					
					{/* twitter_acc - Cuenta de Twitter */}
					{/* <div className="col-span-6 sm:col-span-3">
						<label className="block text-sm font-medium text-gray-700">
							Añade tu @ de Twitter
							<input
								{...register(
									"twitter_acc", { required: false, maxLength: 50 }
								)}
								type="text"
								placeholder="Escribe tu @ de Twitter"
								className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF7C9B] focus:ring-[#FF7C9B] sm:text-sm"
							/>
						</label>
					</div> */}


					{/* instagram_acc - Cuenta de instagram */}
					{/* <div className="col-span-6 sm:col-span-3">
						<label className="block text-sm font-medium text-gray-700">
							Añade tu @ de Instagram
							<input
								{...register(
									"instagram_acc", { required: false, maxLength: 50 }
								)}
								type="text"
								placeholder="Escribe tu @ de Instagram"
								className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF7C9B] focus:ring-[#FF7C9B] sm:text-sm"
							/>
						</label>
					</div> */}
					
					{/* type_document - Tipo de documento */}
					<div className="col-span-6 sm:col-span-3">
						<label className="block text-sm font-medium text-gray-700">
							Tipo de persona
							<select
								{...register(
									"person", { required: true}
								)}
								className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-[#FF7C9B] focus:outline-none focus:ring-[#FF7C9B] sm:text-sm"
							>
								<option value='Natural'>Natural</option>
								<option value='Jurídica'>Jurídica</option>
							</select>
						</label>
						{errors.person && 
                            <span className={errorStyles}>
                                Este campo es requerido para continuar
                            </span>
                        }
					</div>
					
					{/* phone - Numero de telefono */}
					<div className="col-span-6 sm:col-span-3">
						<label className="block text-sm font-medium text-gray-700">
							Telefono
							<input
								{...register(
									"phone", { required: true, maxLength: 50 }
								)}
								type="text"
								placeholder="Escribe tu número de teléfono aquí"
								className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF7C9B] focus:ring-[#FF7C9B] sm:text-sm"
							/>
						</label>
						{errors.phone && 
                            <span className={errorStyles}>
                                Este campo es requerido para continuar
                            </span>
                        }
					</div>
					
					{/* type_document - Tipo de documento */}
					<div className="col-span-6 sm:col-span-3">
						<label className="block text-sm font-medium text-gray-700">
							Tipo de documento
							<select
								{...register(
									"type_document", { required: true }
								)}
								className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-[#FF7C9B] focus:outline-none focus:ring-[#FF7C9B] sm:text-sm"
							>
								<option value="CC">Cédula de ciudadanía</option>
								<option value="CE">Cédula de extranjería</option>
								<option value="PA">Pasaporte</option>
							</select>
						</label>
						{errors.type_document && 
                            <span className={errorStyles}>
                                Este campo es requerido para continuar
                            </span>
                        }
					</div>
					
					{/* document - Número de documento */}
					<div className="col-span-6 sm:col-span-3">
						<label className="block text-sm font-medium text-gray-700">
							Documento
							<input
								{...register(
									"document", { required: true, maxLength: 50 }
								)}
								type="text"
								placeholder="Escribe tu número de documento aqui"
								className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF7C9B] focus:ring-[#FF7C9B] sm:text-sm"
							/>
						</label>
						{errors.document && 
                            <span className={errorStyles}>
                                Este campo es requerido para continuar
                            </span>
                        }
					</div>

					{/* country - pais */}
					<div className="col-span-6 sm:col-span-2">
						<label className="block text-sm font-medium text-gray-700">
							País
							<select
								{...register(
									"country", { required: true }
								)}
								type="text"
								placeholder="E"
								className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-[#FF7C9B] focus:outline-none focus:ring-[#FF7C9B] sm:text-sm"
							>
								<option value='CO'>Colombia</option>
								<option value='OTHER'>Otro</option>
							</select>
						</label>
						{errors.country && 
                            <span className={errorStyles}>
                                Este campo es requerido para continuar
                            </span>
                        }
					</div>
					
					{/* municipality - Departamento */}
					<div className="col-span-6 sm:col-span-2 lg:col-span-2">
						<label htmlFor="region" className="block text-sm font-medium text-gray-700">
							Departamento
							<input
								{...register(
									"municipality", { required: true, maxLength: 50 }
								)}
								type="text"
								placeholder="Escribe el departamento de donde vienes"
								className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF7C9B] focus:ring-[#FF7C9B] sm:text-sm"
							/>
						</label>
						{errors.municipality && 
							<span className={errorStyles}>
								Este campo es requerido para continuar
							</span>
						}
					</div>
					
					{/* city - Ciudad */}
					<div className="col-span-6 sm:col-span-2 lg:col-span-2">
						<label htmlFor="city" className="block text-sm font-medium text-gray-700">
							Ciudad
							<input
								{...register(
									"city", { required: true, maxLength: 50 }
								)}
								type="text"
								placeholder="Escribe tu ciudad"
								className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF7C9B] focus:ring-[#FF7C9B] sm:text-sm"
							/>
						</label>
						{errors.city && 
							<span className={errorStyles}>
								Este campo es requerido para continuar
							</span>
						}
					</div>

					{/* address - Dirección */}
					<div className="col-span-6">
						<label htmlFor="addressLine1" className="block text-sm font-medium text-gray-700">
							Dirección
							<input
								{...register(
									"address", { required: true, maxLength: 50 }
								)}
								type="text"
								placeholder="Escribe tu dirección aquí"
								className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF7C9B] focus:ring-[#FF7C9B] sm:text-sm"
							/>
						</label>
						{errors.address && 
							<span className={errorStyles}>
								Este campo es requerido para continuar
							</span>
						}
					</div>

					<div className="col-span-6 pl-1">
						<input
                            className="h-4 w-4 rounded text-[#FF7C9B] focus:ring-[#FF7C9B]"
                            {...register(
                                "policies", { required: true }
                            )}
                            type="checkbox"
                        />
						<label htmlFor="policies" className="pl-2 inline-block text-sm font-medium text-gray-700">
                            Acepto la <a
                                href="https://firebasestorage.googleapis.com/v0/b/poderosas-colombia.appspot.com/o/AUTORIZACIO%CC%81N%20PARA%20EL%20TRATAMIENTO%20DE%20DATOS.docx.pdf?alt=media&token=d47ff3cc-e210-443f-9f3c-2b508141bb56"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span className="text-[#FF7C9B] cursor-pointer">
                                    política de tratamiento de datos
                                </span>
                            </a>
                        </label>
                        {errors.policies && 
                            <span className={errorStyles}>
                                Debes aceptar las políticas de tratamiento de datos para continuar
                            </span>
                        }
					</div>

                </div>

				<div className="py-3 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center border border-transparent bg-[#FF7C9B] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#c9445d] focus:outline-none focus:ring-2 focus:ring-[#c9445d] focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm rounded"
                  >
                    Donar
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#c9445d] focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm rounded"
                    onClick={() => {reset(); setOpen(false)}}
                    ref={cancelButtonRef}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

      </>
    )
  }
  

export default Form