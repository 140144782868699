import './styles.css'

const TotalInput = ({ amount = 0, handleChange = () => {}  }) => {
    return <div className="w-full">
        <div className="relative">
            <div className="pointer-events-none text-[#8B8B8B] absolute inset-y-0 left-0 flex items-center pl-2">
                <span className="text-xl">$</span>
            </div>
            <input
                onChange={event => handleChange(event.target.value)}
                type="text" 
                name="price"
                id="price"
                value={amount}
                required
                className="
                    block bg-white w-full pl-6 pr-2
                    text-xl text-[#8B8B8B] border-[#8B8B8B] rounded-md
                "
            />
        </div>
    </div>
}

export default TotalInput