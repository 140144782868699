import axios from 'axios'

export const useSaveDonationFunction = async (paymentInfo = {
    name: "",
    email: "",
    phone: "",
    type_person: "",
    type_document: "",
    document: "",
    policies: "",
    quantity: "",
    address: "",
    city: "",
    municipality: "",
    day: "",
    attendes: {},
    twitter_acc: "",
    instagram_acc: "",
    total: 0,
    type: {
        key: ""
    }
}) => {
    try {
        const payload_db = {
            'currency': 'COP',
            'amountInCents': Number(paymentInfo.total + '00'),
            'total': paymentInfo.total,
            'email': paymentInfo.email,
            'fullName': paymentInfo.name,
            'phoneNumberPrefix': paymentInfo.phoneNumberPrefix || '+57',
            'phoneNumber': paymentInfo.phone,
            'typePerson': paymentInfo.person,
            'legalIdType': paymentInfo.type_document,
            'legalId': paymentInfo.document,
            'policies': new Date().toLocaleString(),
            'collectCustomerLegalId': "true",
            'addressLine1': paymentInfo.address,
            'city': paymentInfo.city || '',
            'region': paymentInfo.municipality || '',
            'country': paymentInfo.country || 'CO',
            'publicKey': process.env.REACT_APP_WOMPI_PUBLIC_KEY,
            'redirectUrl': process.env.REACT_APP_WOMPI_REDIRECT_URL,
            'status': 'STARTED',
            'donationType': paymentInfo.type.key
            // 'twitter_acc': paymentInfo.twitter_acc,
            // 'instagram_acc': paymentInfo.instagram_acc
          }

        if (process.env.REACT_APP_ENV === 'test') payload_db.test = true

        const res = await axios({
            method: 'POST',
            url: process.env.REACT_APP_FUNCTIONS_URL + '/saveDonationPoderosas',
            headers: {
                authorization: `Bearer ${process.env.REACT_APP_FUNCTIONS_TOKEN}`
            },
            data: payload_db
        })
        if (payload_db.donationType === 'monthly') {
            return {
                ...payload_db,
                id: res.data.data.id,
            }
        }
        // console.log(res)
        // 2. Payment Payload
        const wompi_payload = {
            'donationType': payload_db.donationType,
            'reference': res.data.data.id,
            'publicKey': payload_db.publicKey,
            'redirectUrl': payload_db.redirectUrl,
            'currency': payload_db.currency,
            'amountInCents': payload_db.amountInCents,
            'customerData': {
                'email': payload_db.email,
                'fullName': payload_db.fullName,
                'phoneNumberPrefix': payload_db.phoneNumberPrefix,
                'phoneNumber': payload_db.phoneNumber,
                'legalIdType': payload_db.legalIdType,
                'legalId': payload_db.legalId,
            },
            'collectCustomerLegalId': payload_db.collectCustomerLegalId,
            'shippingAddress': {
                'addressLine1': payload_db.addressLine1,
                'city': payload_db.city,
                'phoneNumber': payload_db.phoneNumber,
                'region': payload_db.region,
                'country': payload_db.country,
            }
        }
        // console.log(wompi_payload)
        return wompi_payload
    } catch (error) {
        // console.log('err usePaymentFunction:', error)
        throw new Error(error)
    }
}